<template>
  <div>Live</div>
</template>

<script>
export default {
  name: "Live"
}
</script>

<style scoped>

</style>